import { useMemo } from "react";
import { bool, number } from "prop-types";

import formatNumber from "../../utilities/format-number";

import { Typography } from "@uhc-tempo/components";
const { Paragraph } = Typography;

export default function ShoppingBenefitPoints({ points, starting }) {
  const pointsFormatted = useMemo(() => formatNumber(points), [points]);

  return (
    <Paragraph
      bodyStyle={3}
      className="ua-border-radius-sm ua-bg-accent-one-extra-light ua-padding-md ua-text-center ua-text-accent-one tds-margin-none"
    >
      {starting && <small className="ua-display-block">Starting at</small>}
      <span className="tds-text__paragraph--bold">{pointsFormatted}</span> pts
    </Paragraph>
  );
}

ShoppingBenefitPoints.propTypes = {
  points: number,
  starting: bool,
};
