export const canAccessPerks = [
  "Super Admin",
  "UHC Admin",
  "UHC Market Leader",
  "UHC User",
  "User - Agency Primary",
];

export const canAccessShopping = ["User - Agency Primary"];

export const level2AndUp = ["level_2", "level_3"];

const ROUTES = {
  benefits: {
    dynamic: true,
    path: "/shopping/perks/:benefitSlug",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Perk",
  },
  // coach: {
  //   path: "/advantage-coach",
  //   restricted: true,
  //   restrictedToLevels: level2AndUp,
  //   title: "Advantage Coach",
  // },
  comingSoon: {
    path: "/coming-soon",
    restricted: true,
    title: "Coming soon",
  },
  dashboard: {
    path: "/home",
    restricted: true,
    restrictedToLevels: level2AndUp,
    title: "Home",
  },
  dcsm: {
    forceHideHeader: true,
    path: "/dcsm",
    restricted: false,
    title: "Dedicated Client Service Manager (DCSM)",
  },
  default: {
    path: "/",
    restricted: false,
    reverseHeader: true,
  },
  expectations: {
    forceHideHeader: true,
    path: "/2024expectations",
    restricted: false,
    title: "2024 expectations",
  },
  forgotPassword: {
    path: "/password/forgot",
    restricted: false,
    title: "Forgot password",
  },
  invite: {
    path: "/invite",
    restricted: true,
    restrictedToRoles: canAccessPerks,
    title: "Invite team members",
  },
  login: {
    path: "/login",
    restricted: false,
    title: "Login",
  },
  notFound: {
    path: "*",
    restricted: false,
    title: "Not found",
  },
  profile: {
    path: "/profile",
    restricted: true,
    title: "Edit profile",
  },
  privacy: {
    path: "/privacy-policy",
    restricted: false,
    title: "Privacy Policy",
  },
  recap: {
    forceHideHeader: true,
    path: "/2023recap",
    restricted: false,
    title: "2023 recap",
  },
  register: {
    path: "/register",
    restricted: false,
    title: "Register",
  },
  registerUser: {
    path: "/register/register-user",
    restricted: false,
    title: "Register user",
  },
  requestAgencyId: {
    path: "/register/request-agency-id",
    restricted: false,
    title: "Request Agency ID",
  },
  resetPassword: {
    path: "/password/reset/:resetToken",
    restricted: false,
    title: "Reset password",
  },
  shopping: {
    dynamic: true,
    path: "/shopping",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Shop UA Perks",
  },
  shoppingBonusChoose: {
    path: "/shopping/bonus/choose",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Shop now for bonus UA perks",
  },
  shoppingBonusConfirmationLater: {
    path: "/shopping/bonus/confirmation-later",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Save bonus for later confirmation",
  },
  shoppingBonusConfirmationNow: {
    path: "/shopping/bonus/confirmation-now",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Bonus shopping confirmation",
  },
  shoppingBonusIntro: {
    bgBleed: "md",
    path: "/shopping/bonus",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Bonus Shopping",
  },
  shoppingBonusReviewNow: {
    path: "/shopping/bonus/review",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Review and submit bonus",
  },
  shoppingChoose: {
    path: "/shopping/choose",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Shop now",
  },
  shoppingConfirmation: {
    path: "/shopping/confirmation",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Shopping confirmation",
  },
  shoppingIntro: {
    bgBleed: "md",
    path: "/shopping",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Shop United Advantage perks",
  },
  shoppingReview: {
    path: "/shopping/review",
    restricted: true,
    restrictedToLevels: level2AndUp,
    restrictedToRoles: canAccessShopping,
    title: "Review and submit",
  },
  status: {
    dynamic: true,
    path: "/status",
    restricted: true,
    title: "Agency Scorecard",
  },
  teaser: {
    forceHideHeader: true,
    path: "/premier",
    restricted: false,
    title: "",
  },
  termsConditions: {
    path: "/terms-and-conditions",
    restricted: true,
    title: "UA Terms & Conditions",
  },
  termsUse: {
    path: "/terms-of-use",
    restricted: false,
    title: "Terms of Use",
  },
  training: {
    path: "/perks/training",
    restricted: true,
    restrictedToLevels: level2AndUp,
    title: "Training",
  },
  trainingDetails: {
    dynamic: true,
    path: "/training/:trainingSlug",
    restricted: true,
    restrictedToLevels: level2AndUp,
    title: "Perk",
  },
  trainingResources: {
    path: "/training-resources",
    restricted: false,
    title: "Training Resources",
  },
};

export default ROUTES;
